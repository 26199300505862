<template>
  <Modal class="mobile-s" ref="modal" @mounted="modalOpen" :settings="settings">
    <div class="unlock-creator" slot="header">
      <div class="modal-logo">
        <iconic name="mf_lock" />
      </div>
    </div>
    <div class="post-review-confirm" dpadding>
      <div class="title">
        <span>{{ $locale["select_document_type"] }}</span>
      </div>

      <div class="doctype">
        <VerifyUserSteps3DocType @select="doctypeSelect" :selected="id_type" />
      </div>

      <div class="progress" v-if="progress">
        <span>{{ progress + " %" }}</span>
      </div>

      <div class="options">
        <a class="options-button __cancel" @click="modalClose">{{ $locale["words"]["cancel"] }}</a>
        <a :class="`options-button __confirm ${$loading ? ' noevents' : ''} ${id_type ? '__enable' : '__disabled'}`" @click="sendReview">
          <span v-if="$lockapp || $loading"><iconic name="gspinner" /></span>
          <span v-else>{{ $locale["words"]["confirm"] }}</span>
        </a>
      </div>
    </div>
  </Modal>
</template>

<script>
export default {
  props: ["type"],
  components: {
    VerifyUserSteps3DocType: () => import("./VerifyUserSteps3DocType.vue"),
  },
  data: function() {
    return {
      id_type: "",
      axios: {
        headers: {
          "use-form": "false",
          verifyType: this.type,
          files: {},
        },
      },
      settings: {
        opened: true,
        class: "view menu bottom mk_head",
        animation: "fadeIn",
      },
    };
  },
  methods: {
    doctypeSelect: function(id_type) {
      this.id_type = id_type;
    },
    modalOpen: function() {
      this.$refs.modal.open();
    },
    modalClose: function() {
      this.$refs.modal.close();
    },
    goToStep4: function() {
      if (this.isPath("/user/creator")) {
        this.$router.push(`/user/creator?apply=step4`).catch(() => {});
      } else {
        this.$router.push(`/user/verify?apply=step4`).catch(() => {});
      }
    },
    reviewResponse: function({ data: { success, message } }) {
      this.isLoading({ state: false, lockapp: false });
      if (success) {
        this.$store.commit("setApplyStep", { step: "step3", value: true });
        this.goToStep4();
        this.modalClose();
        this.$store.dispatch("getProfile");
      } else {
        this.ialert.go({ type: "error", title: "Error", message });
      }
    },
    sendReview: async function() {
      try {
        this.isLoading({ state: true, lockapp: true });
        this.getFilesInfo();
        const formData = new FormData(document.querySelector(".creator-step-3-form"));
        const endPoint = `${process.env.VUE_APP_API_HOST}user/verifyDocs`;
        formData.append("id_type", this.id_type);
        const req = await this.$api.post(endPoint, formData, this.axios);
        this.reviewResponse(req);
      } catch (error) {
        this.reviewResponse({ data: { success: false, message: error.message } });
      }
    },
    getFilesInfo: function() {
      let [files, info, totalSize] = [document.querySelectorAll(".creator-step-3-form input[type=file]"), {}, 0];
      [].forEach.call(files, (input) => {
        let [inputName, file] = [input.name, input.files[0]];
        info[inputName] = { name: file && file.name, size: file && file.size };
        if (file) totalSize += file.size;
      });

      info["id_type"] = this.id_type;
      info["totalSize"] = totalSize;
      this.axios.headers.files = JSON.stringify(info);
    },
  },
  beforeMount: function() {
    this.$store.commit("resetSocketProgress");
    this.id_type = this.profile.user?.identity?.id_type;
  },
  computed: {
    profile: function() {
      return this.$store.state.user.profile;
    },
    progress: function() {
      return this.$store.state.socket.progress;
    },
  },
};
</script>

<style lang="scss">
.post-review-confirm {
  text-align: center;
  .title {
    font-size: 120%;
    padding: 0 0 $mpadding/2 0;
    [status] {
      margin: 0 0 0 $mpadding/2;
    }
  }
  .options {
    @include Flex(inherit, space-evenly, center);
    padding: $mpadding 0 0 0;
    gap: $mpadding;
    &-button {
      @include Flex(inherit, center, center);
      cursor: pointer;
      flex: 1;
      padding: $mpadding;
      border-radius: $mradius/2;
      user-select: none;
      &.__cancel {
        background-color: $alto;
      }
      &.__confirm {
        background-color: $primary_color;
        color: #fff;
      }
      &:active {
        @include d-active;
      }
      &.__disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}
</style>
